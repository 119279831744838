import { axiosPrivate } from "../axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  return token;
};

axiosPrivate.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
axiosPrivate.interceptors.response.use(
  (response) => response,
  async (err) => {
    const prevRequest = err?.config;
    if (err?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newToken = await api.updateAccessToken();
      if (newToken) {
        prevRequest.headers["Authorization"] = `Bearer ${newToken}`;
        if (!prevRequest.headers["Content-Type"]) {
          prevRequest.headers["Content-Type"] = "multipart/form-data";
        }
        return axiosPrivate(prevRequest);
      }
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/";
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

const api = {
  login: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`auth/admin-login`, data);
        resolve(response.data);
      } catch (err) {
        resolve(err.response.data);
      }
    });
  },
  updateAccessToken: () => {
    return new Promise(async (resolve) => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user?._id;
        const response = await axiosPrivate.post(`auth/update-token`, {
          userId: userId,
        });
        let token = localStorage.getItem("token");
        if (token) {
          localStorage.setItem("token", response.data.token);
        } else {
          sessionStorage.setItem("token", response.data.token);
        }
        resolve(response.data.token);
      } catch (err) {
        console.log(err.response);
        resolve(null);
      }
    });
  },
  logout: () => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`admin/logout`);
        resolve(response);
      } catch (err) {
        resolve(err.response);
      }
    });
  },
  getPayoutData: (data) => {
    return new Promise(async (resolve) => {
      let response = await axiosPrivate.put(`admin/payout-requests`, data);
      resolve(response.data);
    });
  },
  processPayout: (payoutId) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(
          `admin/confirm-payout/${payoutId}`
        );
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getMatchData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/match-list`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getGameData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/get-games`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  addGame: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/add-game`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  changeGameImage: (data) => {
    return new Promise(async (resolve) => {
      try {
        const formData = new FormData();
        formData.append("game-images", data.file);
        formData.append("objectKey", data.objectKey);

        let response = await axiosPrivate.put(
          `admin/update-game-image?gameId=${data.gameId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  updateMatch: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/update-match`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  refundAll: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axiosPrivate.post(`admin/refund`, data);
        resolve({ id: "success", message: response.data.message });
      } catch (error) {
        resolve({ id: "error", message: error.response.data.message });
      }
    });
  },
  getScreenSettings: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axiosPrivate.get(`admin/screen-settings`);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
        reject("error.response?.data?.message");
      }
    });
  },
  updateScreenSettings: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axiosPrivate.post(`admin/screen-settings`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
        reject("error.response?.data?.message");
      }
    });
  },
  setMatchWinner: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/set-winner`, data);
        resolve(response);
      } catch (error) {
        resolve(error.response);
      }
    });
  },
  getTournamentData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/tournament-list`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  updateTournament: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/update-tournament`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getDashboardData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/home-data`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  postAnnouncement: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`admin/send-announcement`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getAnnouncementData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/get-announcements`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getDisputeData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/match-list`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getComplaintsData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/get-complaints`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  handleComplaint: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(
          `admin/handle-complaint/${data.id}`,
          { adminMessage: data?.reply }
        );
        resolve(response.data);
      } catch (error) {
        resolve("error");
        console.log(error.response?.data?.message);
      }
    });
  },
  getUserData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/user-list`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  createAdmin: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`admin/create-admin`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getAdminData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/get-admins`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  updateAdmin: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/update-admin`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getUsers: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`users/page`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getMatchUsersData: (matchId) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.get(
          `admin/match-details?matchId=${matchId}`
        );
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getTournamentUsersData: (tournamentId) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.get(
          `admin/tournament-details?tournamentId=${tournamentId}`
        );
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  addProductCategory: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(
          `admin/add-product-category`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve(error.response);
      }
    });
  },
  getProductCategory: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/get-category`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  deleteProductCategory: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.delete(`admin/delete-category`, {
          data: data,
        });
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve(error.response);
      }
    });
  },
  updateUserData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/update-user`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },

  deleteUser: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.delete(`admin/delete-user`, {
          data: data,
        });
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve(error.response);
      }
    });
  },
  deleteMatches: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.delete(`admin/delete-match`, {
          data: data,
        });
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve(error.response);
      }
    });
  },
  deleteTournaments: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.delete(`admin/delete-tournament`, {
          data: data,
        });
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve(error.response);
      }
    });
  },
  getKycData: (page) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.get(
          `admin/wallet-identification?page=${page}`
        );
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  approveWallet: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.post(`admin/approve-wallet`, data);
        resolve(response);
      } catch (error) {
        console.log(error.response?.data?.message);
        resolve("error");
      }
    });
  },
  getTransactionData: (data) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.put(`admin/transactions`, data);
        resolve(response.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    });
  },
  getSDKMatchStreamRecording: (streamId) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.get(
          `admin/get-stream-record/${streamId}`
        );
        resolve(response.data);
      } catch (error) {
        resolve(error.response);
        console.log(error.response?.data?.message);
      }
    });
  },
  getTwitchMatchStreamRecording: (streamId) => {
    return new Promise(async (resolve) => {
      try {
        let response = await axiosPrivate.get(
          `admin/twitch-recording/${streamId}`
        );
        resolve(response.data);
      } catch (error) {
        resolve(error.response);
        console.log(error.response?.data?.message);
      }
    });
  },
};

export default api;
